@import '../../../style/font-mixins';
// Mixin to create the border and spacings for inputs, form-fields, ...
@mixin dt-form-control() {
  box-sizing: border-box;
  border: 1px solid $gray-300;
  border-radius: 3px;
  min-height: 32px;
  background-color: #ffffff;
  // Strangely setting the lineheight to 29 instead of 30
  // centers the text more consistent
  @include dt-custom-font-styles($custom-line-height: 29px);

  .dt-theme-dark & {
    border-color: $gray-400;
  }
}

@mixin dt-form-control-body() {
  display: flex;

  // Do not set `align-items` as it breaks SVGs baseline alignment
  // align-items: baseline;
  box-sizing: border-box;

  width: 100%;
  @include dt-form-control();

  // We need to set overflow visible to prevent the outline
  // of inner interactive elements (like buttons) to be cut off
  overflow: visible;
}

@mixin dt-form-control-errors() {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 1px solid $gray-300;
  background-color: $gray-100;
  padding: 8px 12px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  transform-origin: top left;

  // Make sure the error is not overlapped by other elements
  z-index: 1;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    left: -1px;
    right: -1px;
    height: 2px;
    background-color: $error-color;
  }
}

@mixin dt-form-control-hint() {
  @include dt-label-font();
  text-decoration: none;
  appearance: none;
  display: block;
  box-sizing: border-box;
  white-space: normal;
}

@mixin dt-form-control-placeholder() {
  opacity: 1; // Because it's different in eg.: Firefox
  color: $placeholdercolor;
}

@mixin dt-form-control-disabled-placeholder() {
  color: $disabledcolor;
}
