/*
 * core styles for the component library
 */
@import '../core/src/style/variables';

@import './font-mixins';
@import '../input/src/input';
@import '../indicator/src/indicator';
@import './link';

@import '../core/src/style/overlay';

@import './themes';

@import '~@angular/cdk/overlay';
@import '~@angular/cdk/a11y';

@include cdk-a11y();
@include cdk-overlay();

.dt-no-pointer {
  pointer-events: none;
}

:root {
  --dt-theme-main-default-color: #{$turquoise-600};
  --dt-theme-main-hover-color: #{$turquoise-700};
  --dt-theme-main-active-color: #{$turquoise-800};
  --dt-theme-main-background-color: #{$turquoise-100};
  --dt-warning-default-color: #{$yellow-600};
  --dt-warning-hover-color: #{$yellow-700};
  --dt-warning-active-color: #{$yellow-800};
  --dt-error-default-color: #{$red-600};
  --dt-error-hover-color: #{$red-700};
  --dt-error-active-color: #{$red-800};
  --dt-recovered-default-color: #{$green-600};
  --dt-recovered-hover-color: #{$green-700};
  --dt-recovered-active-color: #{$green-800};
  --dt-neutral-default-color: #{$gray-600};
  --dt-neutral-hover-color: #{$gray-700};
  --dt-neutral-active-color: #{$gray-800};
  --dt-cta-default-color: #{$green-600};
  --dt-cta-hover-color: #{$green-700};
  --dt-cta-active-color: #{$green-800};
}
