.preloader {
  position:fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  z-index:999999;
  opacity:1;
  background: rgba($color-page, 0.3);
}

.lds-ripple {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -90px;
  margin-left: -75px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 72px;
    left: 72px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 144px;
    height: 144px;
    opacity: 0;
  }
}
