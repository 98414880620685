.dt-indicator-active.dt-color-error {
  --dt-indicator-color: var(--dt-error-default-color);
}
.dt-indicator-active.dt-color-warning {
  --dt-indicator-color: var(--dt-warning-default-color);
}

.dt-indicator-active {
  color: var(--dt-indicator-color);
}
