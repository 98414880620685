@import './variables';

// Mixin overriding default focus styles and setting our own
@mixin dt-focus-style($is-dark: false) {
  outline-width: 2px;
  outline-style: solid;
  outline-offset: 1px;
  @if ($is-dark == true) {
    outline-color: $gray-100;
  } @else {
    outline-color: $gray-400;
  }
}

@mixin dt-no-focus-style() {
  outline-offset: 0;
  outline: transparent none 0;
}

// Mixin applying the cdk-keyboard-focus and cdk-program-focus
// to the calling selector and applies the dt-focus-style
@mixin dt-cdkmonitor-focus-style($is-dark: false) {
  &:focus {
    outline: none;
  }

  &.cdk-focused.cdk-keyboard-focused,
  &.cdk-focused.cdk-program-focused {
    @include dt-focus-style($is-dark);
  }
}

// Mixin overriding default styles for interactive elements (button, ..)
// like the gray background, the border, and the outline.
@mixin dt-interactive-reset() {
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
}
