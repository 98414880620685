.table {
  font-size: 12px;
  
  background-color: #F2F2F2 !important;
  
  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th {
    padding: 12px 7px;
    vertical-align: middle;
    color: black;
    font-weight: bold;
    
    border-bottom: 2px solid #969da3!important;/*cambiar el color de la lineas que van en medio de la tabla (filas) */
  }
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    padding: 12px 7px;
    vertical-align: middle;
    font-weight: normal;
    color: #969da3;
    border-bottom: 2px solid #969da3!important;/*cambiar el color de la lineas que van en medio de la tabla (filas) */
  }

  > tbody > tr {
    position: relative;
  }

  .table {
    background-color: #F2F2F2 !important; /*cambiar el color de fondo de la tabla que se delplega*/
    margin-bottom: 0;

    tr {
      &.active {
        border-bottom: unset !important;
      }
    }
  }

  tbody + tbody {
    border-top: unset;
  }

  .tree-table {
    background-color: rgb(252, 214, 0) !important;

    td .master-button {
      background-color: transparent !important;
      border: 0 !important;
      color: $white-color !important;
      padding: 0 5px 0 0 !important;
      vertical-align: middle !important;
    }
  }
}

.table-shopping {
  > thead > tr > th {
    font-size: $font-size-h6;
    text-transform: uppercase;
  }

  > tbody > tr > td {
    font-size: $font-paragraph;

    b {
      display: block;
      margin-bottom: 5px;
    }
  }

  .td-name {
    font-weight: $font-weight-normal;
    font-size: 1.5em;

    small {
      color: $dark-gray;
      font-size: 0.75em;
      font-weight: $font-weight-light;
    }
  }

  .td-number {
    font-weight: $font-weight-light;
    font-size: $font-size-h4;
  }

  .td-name {
    min-width: 200px;
  }

  .td-number {
    text-align: right;
    min-width: 170px;

    small {
      margin-right: 3px;
    }
  }

  .img-container {
    width: 120px;
    max-height: 160px;
    overflow: hidden;
    display: block;

    img {
      width: 100%;
    }
  }
}

.table-responsive {
  overflow: auto;
  padding-bottom: 10px;
  width: auto;
}
/*
@media (max-width:1195px){
	.table-responsive { width: 707px; }
}*/

/*
@media (max-width:840px){
	.table-responsive { overflow: auto; }
}

@media (max-width:500px){
	.table-responsive { width: 370px; }
}
*/




#tables {
  margin-bottom: 30px;
}

.table-detalle {
  color: black;
  td {
    padding: 5px !important;
    text-align: left !important;

    &.title {
      font-weight: bold;
    }
  }
}

.table-bordered {
  /*border: 1px solid blue; no queremos mientas este border*/ 

  th,
  td {
    text-align: center;
    /*border: 1px solid blue;*/ /*cambiar el borde de toda la tabla incluyendo la que se desplega*/
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    cursor: default;
  }

  th {
    /*Aqui se modifica el color del texto y el color de fondo del header principal*/
    color:#969da3 !important;
  }

  tbody tr {
    &.active {
      /*border-bottom: 2px solid blue;*/
    }

  }

  td i {
    cursor: pointer;
  }

  &.nested {
    .first-col {
      width: 2% !important;
      white-space: nowrap;
    }

    td, th {
      text-align: center;
    }

    tbody {
      tr {
        &.tr-detail > td {
          padding-top: 0;
          padding-left: 0;
          padding-right: 0;
        }

        &.tr-selected > td:not(:first-child) {
          /*background-color: blue;*/
          color:#969da3 !important;
        }

        &:nth-child(odd) {
          background-color: initial;
        }

        span.fa {
          -webkit-transition: all 400ms;
          transition: all 400ms;

          &:hover {
            cursor: pointer !important;
            opacity: 0.7;
          }
        }

        .detail-content ul {
          padding-left: 0;

          li {
            /*border: 2px dashed blue;*/
            padding-top: 0.7rem;
            border-width: 0 0 0px 1px;
            display: -webkit-box;
            display: flex;

            .detail {
              fieldset {
               /* border: 1px solid blue;*/

                legend {
                  font-size: 90%;
                  margin-left: 5px;
                  width: auto;
                  margin-bottom: 10px;

                  span {
                    border: 1px solid #757575 !important;
                    border-radius: 12px;
                    padding: 3px;
                    color:#969da3;
                    font-weight: bold;
                    
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}






