@import '../../style/font-mixins';
@import '../../core/src/style/variables';
@import '../../core/src/style/interactive-common';
@import '../../core/src/style/form-control';

.dt-input {
  @include dt-main-font();
  appearance: none;
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  text-decoration: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
  line-height: -moz-block-height;
  vertical-align: middle;
  white-space: nowrap;
  text-align: left;
  border: none;
  width: 100%;
  outline: none;
  @include dt-form-control();

  &::placeholder {
    @include dt-form-control-placeholder();
  }

  &[disabled]::placeholder {
    @include dt-form-control-disabled-placeholder();
  }

  &:hover:not([disabled]) {
    border-color: $gray-500;
  }

  .dt-form-field-infix > & {
    border: none;
    border-radius: none;
    min-height: 30px;
  }

  :not(.dt-form-field-infix) > &:focus:not([disabled]),
  :not(.dt-inline-editor-infix) > &:focus:not([disabled]) {
    @include dt-focus-style();
  }

  &[disabled] {
    background-color: $gray-130;
    color: $disabledcolor;

    .dt-theme-dark & {
      color: $gray-400;
      background-color: $gray-500;
      border-color: $gray-400;
    }
  }

  // Removes the red outline in firefox
  box-shadow: none;
  &:focus {
    box-shadow: none !important;
  }
}

textarea.dt-input {
  line-height: 1.5;
  resize: none;
  padding-top: 6px;
  padding-bottom: 6px;
  white-space: pre-wrap;
}
