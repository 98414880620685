@import './colors';

$warning-color: $yellow-600;
$warning-color-hover: $yellow-700;
$warning-color-active: $yellow-800;

$error-color: $red-500;
$error-color-hover: $red-600;
$error-color-active: $red-700;

$cta-color: $green-500;
$cta-color-hover: $green-600;
$cta-color-active: $green-700;

$textcolor: $gray-700;
$focuscolor: $gray-300;
$linkcolor: $turquoise-600;
$disabledcolor: $gray-300;
$placeholdercolor: $gray-500;

$border-radius: 3px;
$focus-outline-width: 2px;

$monospace-font-family: 'VeraMonoWeb';
$default-font-family: 'BerninaSansWeb', 'OpenSans', sans-serif;
$default-font-color: $textcolor;
$default-font-weight: normal;
$default-font-size: 14px;
$default-line-height: 1.6;
