/*Fiscalia theme*/
/*Fiscalia theme*/
/*     brand Colors              */
/*      light colors         */
.table {
  font-size: 12px;
  background-color: #F2F2F2 !important;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th {
  padding: 12px 7px;
  vertical-align: middle;
  color: black;
  font-weight: bold;
  border-bottom: 2px solid #969da3 !important;
  /*cambiar el color de la lineas que van en medio de la tabla (filas) */
}
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 12px 7px;
  vertical-align: middle;
  font-weight: normal;
  color: #969da3;
  border-bottom: 2px solid #969da3 !important;
  /*cambiar el color de la lineas que van en medio de la tabla (filas) */
}
.table > tbody > tr {
  position: relative;
}
.table .table {
  background-color: #F2F2F2 !important;
  /*cambiar el color de fondo de la tabla que se delplega*/
  margin-bottom: 0;
}
.table .table tr.active {
  border-bottom: unset !important;
}
.table tbody + tbody {
  border-top: unset;
}
.table .tree-table {
  background-color: #fcd600 !important;
}
.table .tree-table td .master-button {
  background-color: transparent !important;
  border: 0 !important;
  color: #ffffff !important;
  padding: 0 5px 0 0 !important;
  vertical-align: middle !important;
}

.table-shopping > thead > tr > th {
  font-size: 1em;
  text-transform: uppercase;
}
.table-shopping > tbody > tr > td {
  font-size: 1em;
}
.table-shopping > tbody > tr > td b {
  display: block;
  margin-bottom: 5px;
}
.table-shopping .td-name {
  font-weight: 400;
  font-size: 1.5em;
}
.table-shopping .td-name small {
  color: #9a9a9a;
  font-size: 0.75em;
  font-weight: 300;
}
.table-shopping .td-number {
  font-weight: 300;
  font-size: 1.714em;
}
.table-shopping .td-name {
  min-width: 200px;
}
.table-shopping .td-number {
  text-align: right;
  min-width: 170px;
}
.table-shopping .td-number small {
  margin-right: 3px;
}
.table-shopping .img-container {
  width: 120px;
  max-height: 160px;
  overflow: hidden;
  display: block;
}
.table-shopping .img-container img {
  width: 100%;
}

.table-responsive {
  overflow: auto;
  padding-bottom: 10px;
  width: auto;
}

/*
@media (max-width:1195px){
	.table-responsive { width: 707px; }
}*/
/*
@media (max-width:840px){
	.table-responsive { overflow: auto; }
}

@media (max-width:500px){
	.table-responsive { width: 370px; }
}
*/
#tables {
  margin-bottom: 30px;
}

.table-detalle {
  color: black;
}
.table-detalle td {
  padding: 5px !important;
  text-align: left !important;
}
.table-detalle td.title {
  font-weight: bold;
}

.table-bordered {
  /*border: 1px solid blue; no queremos mientas este border*/
}
.table-bordered th,
.table-bordered td {
  text-align: center;
  /*border: 1px solid blue;*/
  /*cambiar el borde de toda la tabla incluyendo la que se desplega*/
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  cursor: default;
}
.table-bordered th {
  /*Aqui se modifica el color del texto y el color de fondo del header principal*/
  color: #969da3 !important;
}
.table-bordered tbody tr.active {
  /*border-bottom: 2px solid blue;*/
}
.table-bordered td i {
  cursor: pointer;
}
.table-bordered.nested .first-col {
  width: 2% !important;
  white-space: nowrap;
}
.table-bordered.nested td, .table-bordered.nested th {
  text-align: center;
}
.table-bordered.nested tbody tr.tr-detail > td {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.table-bordered.nested tbody tr.tr-selected > td:not(:first-child) {
  /*background-color: blue;*/
  color: #969da3 !important;
}
.table-bordered.nested tbody tr:nth-child(odd) {
  background-color: initial;
}
.table-bordered.nested tbody tr span.fa {
  -webkit-transition: all 400ms;
  transition: all 400ms;
}
.table-bordered.nested tbody tr span.fa:hover {
  cursor: pointer !important;
  opacity: 0.7;
}
.table-bordered.nested tbody tr .detail-content ul {
  padding-left: 0;
}
.table-bordered.nested tbody tr .detail-content ul li {
  /*border: 2px dashed blue;*/
  padding-top: 0.7rem;
  border-width: 0 0 0px 1px;
  display: -webkit-box;
  display: flex;
}
.table-bordered.nested tbody tr .detail-content ul li .detail fieldset {
  /* border: 1px solid blue;*/
}
.table-bordered.nested tbody tr .detail-content ul li .detail fieldset legend {
  font-size: 90%;
  margin-left: 5px;
  width: auto;
  margin-bottom: 10px;
}
.table-bordered.nested tbody tr .detail-content ul li .detail fieldset legend span {
  border: 1px solid #757575 !important;
  border-radius: 12px;
  padding: 3px;
  color: #969da3;
  font-weight: bold;
}